import {mapGetters} from "vuex";
const user = JSON.parse(localStorage.getItem('user'));
export default {
    computed: {
        ...mapGetters('users', ['users']),
        ...mapGetters('source_groups', ['sources', 'totalSources']),
    },
    methods: {
      loadAddition(ownerId) {
          const sourcePayload = {
            order_by: "released_at",
            order_type: "desc",
            limit: this.sourcesLimit,
            offset: this.sourcesOffset
          };
          if(ownerId !== user.id) {
              sourcePayload['filters'] = {
                  user_id: ownerId
              };
          }
          this.usersLoading = true;
            Promise.all([
                this.$store.dispatch('users/getUsersList', {filters: {
                        parent_or_self: ownerId,
                    },
                    limit: this.usersLimit,
                    offset: this.offset
                }),
                this.$store.dispatch('source_groups/getSources', sourcePayload)
            ]).finally(() => {
                this.isLoading = false;
                this.usersLoading = false;
            })
        },
        loadAdditionCreate(ownerId) {
            this.usersLoading = true;
            Promise.all([
                this.$store.dispatch('users/getUsersList', {filters: {
                        parent_or_self: ownerId,
                    },
                    limit: this.usersLimit,
                    offset: this.offset
                }),
                this.$store.dispatch('source_groups/getSources', {
                    order_by: "released_at",
                    order_type: "desc",
                    limit: this.sourcesLimit,
                    offset: this.sourcesOffset
                })
            ]).finally(() => {
                this.isLoading = false;
                this.usersLoading = false;
            })
        },
        loadSources(ownerId) {
            const sourcePayload = {
                order_by: "released_at",
                order_type: "desc",
                limit: this.sourcesLimit,
                offset: this.sourcesOffset
              };
            if(ownerId !== user.id) {
                sourcePayload['filters'] = {
                    user_id: ownerId
                };
            }
            this.isLoading = true;
            this.$store.dispatch('source_groups/getSources', sourcePayload)
            .finally(() => {
                this.isLoading = false;
            })
        }
    },
    mounted() {
        this.$store.dispatch('source_groups/clearObjectDetail');
        this.isLoading = true;
        this.$store.dispatch('users/clearUsersList');
        this.$store.dispatch('source_groups/clearSources')
        if(this.payloadProcess === 'create') {
            this.loadAdditionCreate(user.id)
        }
    },
    watch: {
        detailedSourceGroup: {
            deep: true,
            handler(val) {
                if(val.hasOwnProperty('owner')&& !this.sources.length && !this.users.length) {
                    this.loadAddition(val.owner.id);
                }
            }
        },
        
    }
}